<template>
    <div>
        <div
            class="align-items-center border-grey qu-fc-color curser-pointer"
            v-b-modal.export-response-series-questions
            :class="{ 'disabled': questions.length < 1 }"
            :aria-disabled="questions.length < 1"
            :style="{ pointerEvents: questions.length < 1 ? 'none' : 'auto' }">
            <span class="text-nowrap">Export Output</span>
        </div>
       
        <b-modal id="export-response-series-questions" hide-footer size="lg" centered title="Questions and Options" @hidden="resetModal">
            <div class="modal_content">
                <div v-for="(question, index) in questions" :key="index+'-question'">
                
                    <h3>Question: {{ index + 1 }}</h3>
                
                    <div><strong>title:</strong> {{ question.title }}</div>
                    <div><strong>titleTts:</strong> {{ question.titleTts }}</div>
                    <div><strong>plugin:</strong> {{ question.plugin }}</div>

                    <template v-if="question.descriptions && question.descriptions.length > 0">
                        <div v-for="(q, qIndex) in question.descriptions" :key="qIndex+'-descriptions'">
                            <div><strong>descriptions{{qIndex}}title:</strong> {{ q.title }}</div>
                            <div><strong>descriptions{{qIndex}}tts:</strong> {{ q.tts }}</div>
                            <div><strong>descriptions{{qIndex}}image:</strong> {{ q.image }}</div>
                        </div>
                    </template>
                    
                    <template v-if="question.patterns && question.patterns.length > 0">
                        <div v-for="(p, pIndex) in question.patterns" :key="pIndex+'-patterns'">
                            <div><strong>patterns{{ pIndex }}expression:</strong> {{ p.expression }}</div>
                            <div><strong>patterns{{ pIndex }}answer:</strong> {{ p.answer }}</div>
                            <div><strong>patterns{{ pIndex }}image:</strong> {{ p.image }}</div>
                            <template v-if="p.optionsGroups && p.optionsGroups.length > 0">
                                <div v-for="(og, ogIndex) in p.optionsGroups" :key="ogIndex+'-optionsGroups'">
                                    <div><strong>patterns{{ pIndex }}optionsGroups{{ ogIndex }}title:</strong> {{ og.title }}</div>
                                    <div><strong>patterns{{ pIndex }}optionsGroups{{ ogIndex }}tts:</strong> {{ og.tts }}</div>
                                    <div><strong>patterns{{ pIndex }}optionsGroups{{ ogIndex }}image:</strong> {{ og.image }}</div>
                                </div>
                            </template>
                        </div>
                    </template>

                    <div><strong>hintText:</strong> {{ question.text }}</div>
                    <div><strong>hintImage:</strong> {{ question.image }}</div>

                    <!-- {{ question }} -->
                    <br />

                </div>

                <div class="modal-footer">
                    <b-button @click="copyToClipboard" variant="primary">
                        Copy
                    </b-button>
                    <b-button @click="closeModal" variant="danger">
                        Close
                    </b-button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import {
    BButton,
    BAlert,
    BSpinner,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
    components: {
        BButton,
        BAlert,
        BSpinner,
        ToastificationContent,
    },
    data() {
        return {
            questionText: "",
            alertMessageToast: "",
        };
    },
    computed: {
        
    },
    
    methods: {
        getQuestions() {
            console.log('this.questions',this.questions);
        },
        
        resetModal() {
            this.$emit("resetGptModel");
            this.questionText = "";
        },

    formatQuestions() {
        const textToCopy = this.questions.map((question, index) => {
            let questionText = `Question: ${index + 1}\n`;
            questionText += `title: ${question.title?question.title:''}\n`;
            questionText += `titleTts: ${question.titleTts?question.titleTts:''}\n`;
            questionText += `plugin: ${question.plugin?question.plugin:''}\n`;

            if (question.descriptions && question.descriptions.length > 0) {
                question.descriptions.forEach((desc, qIndex) => {
                    questionText += `descriptions${qIndex}title: ${desc.title?desc.title:''}\n`;
                    questionText += `descriptions${qIndex}tts: ${desc.tts?desc.tts:''}\n`;
                    questionText += `descriptions${qIndex}image: ${desc.image?desc.image:''}\n`;
                });
            }

            if (question.patterns && question.patterns.length > 0) {
                question.patterns.forEach((pattern, pIndex) => {
                    questionText += `patterns${pIndex}expression: ${pattern.expression?pattern.expression:''}\n`;
                    questionText += `patterns${pIndex}answer: ${pattern.answer?pattern.answer:''}\n`;
                    questionText += `patterns${pIndex}image: ${pattern.image?pattern.image:''}\n`;

                    if (pattern.optionsGroups && pattern.optionsGroups.length > 0) {
                        pattern.optionsGroups.forEach((group, ogIndex) => {
                            questionText += `patterns${pIndex}optionsGroups${ogIndex}title: ${group.title?group.title:''}\n`;
                            questionText += `patterns${pIndex}optionsGroups${ogIndex}tts: ${group.tts?group.tts:''}\n`;
                            questionText += `patterns${pIndex}optionsGroups${ogIndex}image: ${group.image?group.image:''}\n`;
                        });
                    }
                });
            }

            questionText += `hintText: ${question.text?question.text:''}\n`;
            questionText += `hintImage: ${question.image?question.image:''}\n`;
            return questionText;
        }).join('\n');
        return textToCopy;
    },
    copyToClipboard() {
      const textToCopy = this.formatQuestions();
      navigator.clipboard.writeText(textToCopy).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Notification",
            icon: "BellIcon",
            text: "Copied to clipboard!",
            variant: "success",
          }
        });
      }).catch(err => {
        console.error('Failed to copy text: ', err);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Notification",
            icon: "BellIcon",
            text: "Failed to copy text.",
            variant: "danger",
          }
        });
      });
    },
    showToast(variant) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: "Notification",
                    icon: "BellIcon",
                    text: this.alertMessageToast,
                    variant,
                },
            });
        },
        closeModal() {
            this.$bvModal.hide('export-response-series-questions');
            },
        
        resetModal() {
            this.$emit("resetGptModel");
            this.questionText = "";
        },

    },
    props: ["questions"],
};
</script>

<style>
#add-questions-modal .card-body {
    padding: 0px;
}

.curser-pointer {
    cursor: pointer;
}
ol {
      list-style-type: lower-alpha; 
    }
</style>
